import React from "react"
import { Link } from "gatsby"
import { BsArrowLeft } from "react-icons/bs"
import classes from "./LoyaltyFormSuccess.module.scss"

const LoyaltyFormSuccess = () => {
  return (
    <div className={classes.FormSuccess}>
      <div>
        <h1>CHEERS!</h1>
        <p>Thank you for your entry!</p>
        <Link to="/">
          <span>
            <BsArrowLeft />
          </span>
          Back to our site
        </Link>
      </div>
    </div>
  )
}

export default LoyaltyFormSuccess
